<script>
import { onMounted, toRefs, reactive } from 'vue'
import { useStore } from 'vuex'
import { EMAIL_REGEX, ipqsIsEmailInvalid } from '@/util/validations'

const stepName = 'Email'

export default {
  name: stepName,

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const state = reactive({
      email: {
        value: '',
        error: false
      }
    })

    const isEmailInvalid = async () => {
      const validFormat = EMAIL_REGEX.test(String(state.email.value).toLowerCase())
      if (!validFormat) return true

      return await ipqsIsEmailInvalid(state.email.value)
    }

    const setAnswer = async () => {
      state.email.error = await isEmailInvalid()
      if (state.email.error) return

      const answer = {field: 'email', value: state.email.value}
      store.commit('SET_FIELD', answer)
      emit('next-step', stepName)
    }

    const handler = () => {
      state.email.error = false
    }

    return {
      ...toRefs(state),
      setAnswer,
      handler
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <input
          class="form-input email-input"
          :class="{'email-error' : email.error}"
          type="text"
          placeholder="Email"
          v-model="email.value"
          @input="handler"
        >
      </div>
    </div>

    <div class="row">
      <div class="box">
        <button
          class="main-button main-button-w mt-lg"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/email.scss';
</style>